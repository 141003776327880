.formContainer {
  max-width: xl;
  margin: auto;
  padding: 2;
}

.formContainer fieldset {
  margin-bottom: 1rem;
  border: none;
}

.formContainer legend {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.formContainer table {
  width: 100%;
  border-collapse: collapse;
}

.formContainer td {
  padding: 0.5rem;
}

@media (max-width: 575.98px) {
  .formContainer tr {
    display: flex;
    flex-direction: column;
  }
}

.formContainer input,
.formContainer textarea,
.formContainer select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
  font-size: 1rem;
  box-sizing: border-box;
}

.formContainer input[type='checkbox'],
.formContainer input[type='submit'] {
  width: auto;
  cursor: pointer;
}

.formContainer input[type='submit'] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: #3182ce;
  border: 1px solid transparent;
  transition: background-color 0.2s ease-in-out;
}

.formContainer input[type='submit']:hover {
  background-color: #2b6cb0;
}

.formContainer input[type='submit']:focus {
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.5);
}

.formContainer .file-input {
  position: relative;
  display: inline-block;
  border: 2px dashed #ccc;
  background-color: #ffffff;
  padding: 0.5rem;
}

.formContainer .file-input input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.formContainer .file-input label {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: #3182ce;
  border: 1px solid transparent;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.formContainer .file-input label:hover {
  background-color: #2b6cb0;
}

.formContainer .file-input label:active {
  background-color: #2c5282;
}

.formContainer .file-input {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #718096;
}

.formContainer .file-name {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.formContainer .radioButtons {
  display: flex;
  flex-direction: column;
}

.formContainer .radioButtons input[type='radio'] {
  width: auto;
}
